import { useAvvenimento, useInfoAggiuntiva, useScommessa } from 'features/sport/hooks';
import { getDateAndHoursValue, getLiveMinutes, getUrlManifestazioneByAvvenimento } from 'features/sport/utils/utils';
import { memo, useMemo } from 'react';
import { mergeCompetitorWithScore } from './utils/utils';

import { toIconsEnum } from 'components/Icons';
import { ScommesseEsitoTipoTop } from 'components/scommesseEsitoTipoTop';
import { TimingLivePreMatch } from 'components/timingLivePreMatch';
import { KeyManagerSport } from 'features/sport';
import { useTranslation } from 'hooks/useLingUI';
import { gtag } from 'utility/functions';
import styles from './ScommesseInEvidenzaCard.module.scss';
import { ScommesseInEvidenceCardFooter } from './components/scommesseEvidenceCardFooter';
import { ScommesseInEvidenceCardBody } from './components/scommesseInEvidenceCardBody';
import { ScommesseInEvidenceCardHeader } from './components/scommesseInEvidenceCardHeader';

export type ScommesseInEvidenzaCardProps = {
  scommessaInEvidenzaKey: string;
};

export const ScommesseInEvidenzaCard = memo(function ScommesseInEvidenzaCard({
  scommessaInEvidenzaKey,
}: ScommesseInEvidenzaCardProps) {
  const { t } = useTranslation();
  const key = useMemo(() => new KeyManagerSport(scommessaInEvidenzaKey), [scommessaInEvidenzaKey]);
  const scommessa = useScommessa(key.scommessaKey);
  const avvenimento = useAvvenimento(key.avvenimentoKey);
  const infoAggiuntiva = useInfoAggiuntiva(key.scommessaKey);
  const esitiInEvidenza = infoAggiuntiva?.infoAggiuntivaList?.flatMap((infoAgg) => infoAgg.esitoKeyList) || [];

  const disciplina = `DISCIPLINA_${avvenimento?.idDisciplina}`;

  if (avvenimento) {
    return (
      <div className={styles.container}>
        <ScommesseInEvidenceCardHeader
          disciplinaIcon={toIconsEnum(disciplina)}
          urlManifestazione={getUrlManifestazioneByAvvenimento(avvenimento)}
          manifestazioneLabel={avvenimento?.slugManifestazione!}
          manifestazioneLabelTrKey={avvenimento?.descrizioneManifestazioneTrKey}
        >
          <TimingLivePreMatch
            isLive={!!avvenimento?.live}
            value={
              avvenimento?.live
                ? getLiveMinutes(avvenimento.live.matchTime)
                : avvenimento?.dataOra
                ? `
            ${getDateAndHoursValue(new Date(avvenimento.dataOra))[0]} ${
                    getDateAndHoursValue(new Date(avvenimento.dataOra))[1]
                  }`
                : ''
            }
            // TODO: CHECK THIS minutes={avvenimento?.live?.minutes}
            // TODO: CHECK THIS seconds={avvenimento?.live?.seconds}
          />
        </ScommesseInEvidenceCardHeader>
        <ScommesseInEvidenceCardBody
          avvenimentoKey={avvenimento?.key!}
          dataList={mergeCompetitorWithScore(
            [avvenimento?.firstCompetitor, avvenimento?.secondCompetitor],
            avvenimento?.live?.score
          )}
        />
        <ScommesseInEvidenceCardFooter>
          <span className={styles.scommessaDescription}>{t(scommessa?.descrizioneTrKey, scommessa?.descrizione)}</span>
          <div className={styles.quoteContainer}>
            {esitiInEvidenza?.map((esito) => (
              <ScommesseEsitoTipoTop
                key={esito}
                keyEsito={esito}
                classNameButton={styles.minWidth}
                gtagAction={() => {
                  gtag({ section: 'Sport', detail: 'Eventi in evidenza', event: 'Click quota' }, true);
                }}
              />
            ))}
          </div>
        </ScommesseInEvidenceCardFooter>
      </div>
    );
  }
  return null;
});
